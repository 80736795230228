import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import API from '../../api';
// import { messages } from '../i18n';
import i18n from '../../i18n';
import storageService, { StorageKeys } from '../../services/StorageService';
import { registerLoginEvent } from '../../services/reactGa';
import { AppState, VerificationStep } from '../interfaces';
import { singularClient } from '../../services/SingularClient';
import { setWizardStep } from '../app/acions';
import { isAndroid } from '../../helpers/os';
import { getMarkers } from '../map/actions';
import { nativeMessageService } from '../../services/NativeMessageService';
import { generateAndroidIdentifiers } from '../../api/helpers';
import { isDebugEnabled } from '../../common/isDebugEnables';
import { lyftApiUrl } from '../../config/urls';

import { actionCreators } from './actions';

const makeResponseFulfill = (response) => ({
  data: response?.data,
  status: response?.status,
});

function* handleGetLyftHeaders() {
  try {
    const response = yield call(API.authV2.getParams);
    const {
      access_token_license: accessTokenLicense,
      access_token_params: accessTokenParams,
      access_token_phone: accessTokenPhone,
      account_identifiers: accountIdentifiers,
      tcs_config: tcsConfig,
      phone_auth: phoneAuth,
    } = response;

    yield put(
      actionCreators.getLyftHeaders.success({
        accessTokenLicense,
        accessTokenParams,
        accessTokenPhone,
        accountIdentifiers,
        phoneAuth,
        tcsConfig,
      })
    );
    yield put(actionCreators.getLyftAuthAccessToken.request());
  } catch (e) {
    yield put(actionCreators.getLyftAuthAccessToken.failure());
  }
}

function* handleGetLyftAuthAccessToken() {
  const { authHeaders } = yield select((app: AppState) => app.userForms.verification);
  try {
    const response = yield call(API.lyftApi.access_token, {
      headers: authHeaders.accessTokenParams,
      repeatCount: 1,
      type: 'getLyftAuthAccessToken',
    });

    if (isAndroid) {
      return;
    }

    yield put(actionCreators.getLyftAuthAccessToken.fulfill(makeResponseFulfill(response)));
  } catch (e) {
    yield put(actionCreators.getLyftAuthAccessToken.fulfill(makeResponseFulfill(e.response)));
  }
}

function* handleGetLyftAuthAccessTokenFulfill(action) {
  const { response } = action.payload;

  if (response.status < 300) {
    const { token_type, access_token } = response.data;
    yield put(actionCreators.getLyftAuthAccessToken.success({ token_type, access_token }));
    yield put(actionCreators.sendTcsConfig.request());
    return;
  }

  yield put(actionCreators.getLyftAuthAccessToken.failure());
}

function* handleSendTcsConfig() {
  const { token_type, access_token, authHeaders } = yield select(
    (app: AppState) => app.userForms.verification
  );

  try {
    yield call(API.lyftApi.tcsConfig, {
      headers: authHeaders.tcsConfig,
      token_type,
      access_token,
      params: {
        accessibility_enabled: false,
        client_id: storageService.getDeviceId(),
        // TODO: wtf???
        // eslint-disable-next-line @typescript-eslint/no-loss-of-precision
        variable_configurations_etag: -7927808274011781469,
      },
      type: 'sendTcsConfig',
    });
  } catch (e) {
    console.log(e);
  }
}

function* verifyPhoneNumber({ payload }) {
  const { value } = payload;
  const { token_type, access_token, authHeaders } = yield select(
    (app: AppState) => app.userForms.verification
  );
  try {
    const response = yield call(API.lyftApi.phoneAuth, {
      token_type,
      access_token,
      headers: authHeaders.phoneAuth,
      phone: value,
      type: 'verifyPhoneNumber',
    });

    if (isAndroid) {
      return;
    }

    yield put(actionCreators.verifyPhoneNumber.fulfill(makeResponseFulfill(response)));
  } catch (e) {
    console.log(e);
    yield put(actionCreators.verifyPhoneNumber.fulfill(makeResponseFulfill(e.response)));
  }
}

function* verifyPhoneNumberFulfill(action) {
  const { response } = action.payload;
  const { data, status } = response;

  const { step } = yield select((app: AppState) => app.userForms.verification);

  if (step !== VerificationStep.phoneNumber) {
    return;
  }

  if (status < 300) {
    yield put(actionCreators.setStep(VerificationStep.verificationCode));
    return;
  }

  yield put(
    actionCreators.verifyPhoneNumber.failure(
      data?.error_description || i18n.t('verification.phoneNumber.error')
    )
  );
}

function* verifyVerificationCode({ payload }) {
  const { value } = payload;

  const { phone_number, authHeaders } = yield select((app: AppState) => app.userForms.verification);

  try {
    const response = yield call(API.lyftApi.token, {
      phone: phone_number,
      code: value,
      headers: authHeaders.accessTokenPhone,
      type: 'verifyVerificationCode',
    });

    if (isAndroid) {
      return;
    }

    yield put(actionCreators.verifyVerificationCode.fulfill(makeResponseFulfill(response)));
  } catch (e) {
    yield put(actionCreators.verifyVerificationCode.fulfill(makeResponseFulfill(e.response)));
  }
}

function* verifyVerificationCodeFulfill(action) {
  const { response } = action.payload;
  const { data, status } = response;

  const { step } = yield select((app: AppState) => app.userForms.verification);

  if (step !== VerificationStep.verificationCode && !isDebugEnabled()) {
    return;
  }

  if (status === 401 && response?.data?.challenges[0]?.identifier === 'drivers_license_number') {
    console.log('erastov', {
      status,
      response,
      action: 'yield put(actionCreators.setStep(VerificationStep.licenseNumber));',
    });
    yield put(actionCreators.setStep(VerificationStep.licenseNumber));
    return;
  }

  if (status === 200 && response?.data?.access_token) {
    console.log('erastov', {
      status,
      response,
      action:
        'yield put(actionCreators.verifyLicenceNumber.fulfill(makeResponseFulfill(response)));',
    });
    yield put(actionCreators.verifyLicenceNumber.fulfill(makeResponseFulfill(response)));
    return;
  }

  console.log('erastov', {
    status,
    response,
    action:
      'yield put(\n' +
      '    actionCreators.verifyVerificationCode.failure(\n' +
      "      response?.data?.error_description || i18n.t('verification.phoneNumber.error')\n" +
      '    )\n' +
      '  );',
  });
  yield put(
    actionCreators.verifyVerificationCode.failure(
      response?.data?.error_description || i18n.t('verification.phoneNumber.error')
    )
  );
}

function* verifyLicenceNumber({ payload }) {
  const { value } = payload;

  const { phone_number, authHeaders, verification_code } = yield select(
    (app: AppState) => app.userForms.verification
  );

  try {
    const response = yield call(API.lyftApi.token, {
      phone: phone_number,
      code: verification_code,
      headers: authHeaders.accessTokenLicense,
      drivers_license_number: value,
      repeatCount: 1,
      type: 'verifyLicenceNumber',
    });

    if (isAndroid) {
      return;
    }

    yield put(actionCreators.verifyLicenceNumber.fulfill(makeResponseFulfill(response)));
  } catch (e) {
    yield put(actionCreators.verifyLicenceNumber.fulfill(makeResponseFulfill(e.response)));
  }
}

function* verifyLicenceNumberFulfill(action) {
  const { response } = action.payload;
  const { authHeaders, step } = yield select((app: AppState) => app.userForms.verification);

  if (step !== VerificationStep.licenseNumber && !response?.data?.access_token) {
    return;
  }

  if (response.status < 300) {
    const { extension_code, expires_in, access_token, token_type, user_id, refresh_token } =
      response.data;

    try {
      const { phone_number, license_number } = yield select(
        (app: AppState) => app.userForms.verification
      );

      const response1 = yield call(API.authV2.auth, {
        phone_number,
        drivers_license_number: license_number,
        token_type,
        access_token,
        expires_in,
        refresh_token,
        user_id,
        extension_code,
      });

      const { auth_key, user_photo, user_id: skeddyUserId } = response1;

      if (auth_key && skeddyUserId) {
        // singularClient.login(skeddyUserId);

        registerLoginEvent();

        storageService.setRegistrationData({
          phone_number,
          license_number,
        });

        const isRegistered = storageService.getItem(StorageKeys.isRegistered);

        yield put(actionCreators.verifyLicenceNumber.success());
        yield put(actionCreators.setUser({ user_id: skeddyUserId, auth_key, user_photo }));
        yield put(getMarkers.request());

        if (isAndroid) {
          const accountIdentifierToken = storageService.getAccountIdentifierToken();

          if (accountIdentifierToken) {
            const identifiersHeaders = {
              ...authHeaders.accountIdentifiers,
            };
            identifiersHeaders.authorization = `${token_type} ${access_token}`;

            nativeMessageService.sendNetworkRequestMessage({
              url: `${lyftApiUrl}/v1/accountidentifiers`,
              headers: identifiersHeaders,
              params: {
                identifiers: generateAndroidIdentifiers(accountIdentifierToken),
              },
              method: 'post',
              type: 'accountidentifiers',
            });
          }
        }

        if (!isRegistered) {
          yield put(setWizardStep('intro'));
        }
      }
    } catch (e) {
      // #TODO add show error
      console.log(e);
    }

    return;
  }

  yield put(
    actionCreators.verifyLicenceNumber.failure(
      'The DL number you entered does not match Lyft records. Please, enter the correct DL number.'
    )
  );
}

function* handleLogout() {
  // singularClient.logout();
  const isReviewApp = yield select((appState: AppState) => appState.app.isReviewApp);

  if (isAndroid && !isReviewApp) {
    const response = yield call(API.authV2.getParams);

    if (response.code === 200) {
      const { revoke_token, lyft_access_token } = response;

      const formData = new FormData();
      formData.append('token', lyft_access_token);

      nativeMessageService.sendNetworkRequestMessage({
        url: `${lyftApiUrl}/oauth2/revoke_token`,
        headers: revoke_token,
        params: Object.fromEntries(formData.entries()),
        method: 'post',
        type: 'revokeToken',
      });
    }
  }

  yield call(storageService.clear);
}

export function* watchUserLogout() {
  yield takeLatest(actionCreators.logout.type, handleLogout);
}

export function* watchGetLyftHeaders() {
  yield takeLatest(actionCreators.getLyftHeaders.REQUEST, handleGetLyftHeaders);
}

export function* watchGetLyftAuthAccessToken() {
  yield takeLatest(actionCreators.getLyftAuthAccessToken.REQUEST, handleGetLyftAuthAccessToken);
}

export function* watchGetLyftAuthAccessTokenFulfill() {
  yield takeLatest(
    actionCreators.getLyftAuthAccessToken.FULFILL,
    handleGetLyftAuthAccessTokenFulfill
  );
}

export function* watchSendTcsConfig() {
  yield takeLatest(actionCreators.sendTcsConfig.REQUEST, handleSendTcsConfig);
}

export function* watchVerifyPhoneNumber() {
  yield takeLatest(actionCreators.verifyPhoneNumber.REQUEST, verifyPhoneNumber);
}

export function* watchVerifyPhoneNumberFulfill() {
  yield takeLatest(actionCreators.verifyPhoneNumber.FULFILL, verifyPhoneNumberFulfill);
}

export function* watchVerifyVerificationCode() {
  yield takeLatest(actionCreators.verifyVerificationCode.REQUEST, verifyVerificationCode);
}

export function* watchVerifyVerificationCodeFulfill() {
  yield takeLatest(actionCreators.verifyVerificationCode.FULFILL, verifyVerificationCodeFulfill);
}

export function* watchVerifyLicenceNumber() {
  yield takeLatest(actionCreators.verifyLicenceNumber.REQUEST, verifyLicenceNumber);
}

export function* watchVerifyLicenceNumberFulfill() {
  yield takeLatest(actionCreators.verifyLicenceNumber.FULFILL, verifyLicenceNumberFulfill);
}

export default function* flow() {
  yield fork(watchGetLyftHeaders);

  yield fork(watchGetLyftAuthAccessToken);
  yield fork(watchGetLyftAuthAccessTokenFulfill);

  yield fork(watchSendTcsConfig);

  yield fork(watchVerifyPhoneNumber);
  yield fork(watchVerifyPhoneNumberFulfill);

  yield fork(watchVerifyVerificationCode);
  yield fork(watchVerifyVerificationCodeFulfill);

  yield fork(watchVerifyLicenceNumber);
  yield fork(watchVerifyLicenceNumberFulfill);

  yield fork(watchUserLogout);
}
